/* Portal configurations - global scope */

/* Define a product types for the portal.
* id: product type's id
* name: product type's name
* available:
*/
const helper = {};

const accent_color = 'version2'; // color schema of the portal

const portal_name = 'Runsio';

const activate_website = 1; // 1(all), 2(only portal), 3(only landing)

const landing_name = "Runsio"; // FirstPortal, EbookPortal

const product_type = 4;

const portal_url = "runsio.com";

const country_landing_url = [{
    'UK': 'https://premium.runsio.com/hEVfsX/',
    'EE': 'https://premium.runsio.com/apSxdQ/',
    'LV': 'https://premium.runsio.com/47mbCP/',
    'LT': 'https://premium.runsio.com/nmmlj0/',
    'SK': 'https://premium.runsio.com/RsO001/mo/',
	'RO': 'https://premium.runsio.com/gm9rO2/',
	'FI': 'https://premium.runsio.com/BUvnnE/',
    'DK': 'https://premium.runsio.com/9K07iK/',
    'SE': 'https://premium.runsio.com/GNuxej/',
    'PL': 'https://premium.runsio.com/N5tLjN/',
    'CH': 'https://premium.runsio.com/OdVily/',
    'RS': 'https://premium.runsio.com/AIya5A/',
    'CZ': 'https://premium.runsio.com/RsCz67/',
    'LU': 'https://premium.runsio.com/K6HsmP/',
    'SI': 'https://premium.runsio.com/zFLFk8/',
    'BA': 'https://premium.runsio.com/BavGv3/',
    'SA': 'https://premium.runsio.com/SaArEn/',
    'AT': 'https://premium.runsio.com/Jsbn12/',
    'ME': 'https://premium.runsio.com/mEEm01/',
    'AE': 'https://premium.runsio.com/AeUae1/'
}];

const country_default_language = {
    'UK': 'EN',
    'LU': 'EN',
    'CH': 'DE',
    'SA': 'AR',
    'AT': 'DE',
    'ME': 'BA',
    'AE': 'AR'
};

const product_menu_items = [{
        id: 1,
        name: 'Home',
        available: 1,
        type: 4,
        banner: 0,
        isActive: true,
        countryExclusion: ''
    },
    {
        id: 2,
        name: 'Online Games',
        available: 1,
        type: 1,
        banner: 0,
        isActive: false,
        countryExclusion: ''
    },
    {
        id: 3,
        name: 'Android Games Apps',
        available: 1,
        type: 2,
        banner: 0,
        isActive: false,
        countryExclusion: ''
    },
	{
        id: 6,
        name: 'Apps',
        available: 1,
        type: 6,
        banner: 0,
        isActive: false,
        countryExclusion: ''
    },
	{
        id: 5,
        name: 'Funny Videos',
        available: 1,
        type: 5,
        banner: 0,
        isActive: false,
        countryExclusion: 'SK'
    }
];

const language_phones_format = {
    UK: '+44xxxxxxxxx',
	FI: '04xxxxxxxx',
	EE: '5xxxxxxx',
    LV: '2xxxxxxx',
    LT: '6xxxxxxx',
	RO: '07xxxxxxxx',
	SK: '09xxxxxxxx',
    DK: '47xxxxxxxxx',
    SE: '07xxxxxxxx',
    PL: 'xxxxxxxxx',
    CH: '07xxxxxxxx',
    RS: '06xxxxxxxx',
    CZ: '7xxxxxxxx',
    LU: '352xxxxxxxxx',
    SI: '386xxxxxxxx',
    BA: '06xxxxxxx',
    SA: '05xxxxxxx',
    AT: '06xxxxxxxxx',
    ME: '06xxxxxxx',
    AE: '50xxxxxxx'
};

/*
 * Rating and Favourite feature enabled (1, 0)
 */
const use_rating = 1;
const use_favourite = 1;

// apis
helper.api_PhoneNumber = 'https://api.hubdelivery.io/landing/providePhoneNumberV2';
helper.api_ValidatePin = 'https://api.hubdelivery.io/landing/validatePinCodeV2';
helper.api_SendAccessCodeByEmail = 'https://api.hubdelivery.io/landing/sendAccessCodeByEmail';

helper.api_Search = 'https://api.hubdelivery.io/store/searchProducts';

// tracking api url
helper.trackingAPI = 'https://keepan.eyeonev.com/ent';
helper.ratingsAPI = "https://api.hubdelivery.io/store/addProductRating";
helper.favouriteAPI = "https://api.hubdelivery.io/store/addToFavourite";
helper.favouriteRemoveAPI = "https://api.hubdelivery.io/store/removeFromFavourite";

// tracking default and generated values
helper.affiliate_landing = 'AFFED8bcEzc'; // aff
helper.source_landing = '997PROVID998'; // src
helper.landing_page_id = 'LPC9crQndF8'; // lpc

// apis
helper.landingUrl = country_landing_url;
helper.languagePhoneFormat = language_phones_format;
helper.countryDefaultLanguage = country_default_language;
helper.menu = product_menu_items;
helper.portal_name = portal_name;
helper.portal_type = product_type;
helper.landing_name = landing_name;
helper.portal_url = portal_url;
helper.accent_color = accent_color;
helper.actice_website = activate_website;
helper.hasStars = use_rating;
helper.hasFavourite = use_favourite;

export const helpers = helper;